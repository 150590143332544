<template>
    <div class="home" :style="`heigth:${Height}`">
        <div class="maxwidth">
            <div class="minwidth">
                <h1>Form W-9</h1>
                <h3>Request for Taxpayer Identification Number and Certification</h3>
                <div class="text">This form was created by NAAE in order to receive Form W-9 over
                    online for submitter's convenience purpose. If you still prefer to use PDF form, click below link to
                    print the form and email a scanned copy of completed form to service01@shwlxx.cn</div>
                <a style="margin-top: 20px;display:block;color:#D85427" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                    target="_blank">See Original IRS Form W-9</a>
                <el-form :rules="rules" :model="form" label-width="80px" class="demo-ruleForm" ref="form">
                    <el-form-item label="1. Name (as shown on your income tax return)" prop="First">
                        <el-input v-model="form.First" placeholder="First" style="width: 40%;"></el-input>
                        <el-input v-model="form.Last" placeholder="Last" style="width: 40%;"></el-input>
                    </el-form-item>

                    <!-- <el-form label-width="80px" class="flex-sb" :rules="rules"> -->
                    <div class="flex-sb">
                        <el-form-item label="Gender" prop="radio">
                            <el-radio-group v-model="form.radio">
                                <el-radio label="male">Male</el-radio>
                                <el-radio label="female">Female</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="Date of Birth" prop="rl">
                            <el-date-picker v-model="form.rl" type="date" placeholder="(mm/dd/yyyy)" 
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <!-- </el-form> -->

                    <el-form-item label="2.Business name ​disregarded entity name,if different from above">
                        <el-input v-model="form.above" style="width: 90%;"></el-input>
                    </el-form-item>


                    <el-form-item
                        label="3. Check appropriate box for federal tax classification; check only one of the following seven boxes:"
                        prop="boxes">
                        <el-radio-group v-model="form.boxes" @input="BoxChange">
                            <el-radio label="1">Individual/​sole proprietor or</el-radio>
                            <el-radio label="2">C Corporation</el-radio>
                            <el-radio label="3">S Corporation</el-radio>
                            <el-radio label="4">Partnership</el-radio>
                            <el-radio label="5">Trust/​estate</el-radio>
                            <el-radio label="6">Limited liability company</el-radio>
                            <el-radio label="7">Other (see instructions below)</el-radio>
                        </el-radio-group>
                    </el-form-item>



                    <div class="text">Note. For a single-member LLC that is disregarded, do not check LLC; check the
                        appropriate box in the line above for the tax classification of the single-member owner.</div>
                    <div class="width20"></div>
                    <el-form-item label="Enter the tax classification (C=C corporation, S=S corporation, P=partnership)"
                        v-if="Enter == 6">
                        <el-radio-group v-model="form.corporation">
                            <el-radio label="1">C = C corporation</el-radio>
                            <el-radio label="2">S = S corporation</el-radio>
                            <el-radio label="3">P = Partnership</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="If chosen Other above" v-if="Enter == 7">
                        <el-input v-model="form.chosen" style="width: 60%;"></el-input>
                    </el-form-item>

                    <div class="text">4. Exemptions (Codes apply only to certain entities, not individuals; see instructions
                        on Form W-9)</div>
                    <div class="flex-sb">
                        <el-form-item label="Exempt payee code (if any)">
                            <el-input v-model="form.payee" style="width: 90%;"></el-input>
                        </el-form-item>

                        <el-form-item label="Exemption from FATCA reporting code (if any)">
                            <el-input v-model="form.fatca" style="width: 90%;"></el-input>
                        </el-form-item>
                    </div>

                    <!-- <el-form label-width="80px" :rules="rules"> -->

                    <el-form-item label="Address" prop="Address1">
                        <el-input v-model="form.Address1" placeholder="Address Line 1"></el-input>
                    </el-form-item>

                    <el-form-item label="">
                        <el-input v-model="form.Address2" placeholder="Address Line 2"></el-input>
                    </el-form-item>

                    <div class="flex-sb" style="width:100%">
                        <el-form-item label="" prop="city">
                            <el-input v-model="form.city" placeholder="City"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="State" style="margin-left: 40px;">
                            <el-select v-model="form.State" filterable>
                                <el-option v-for="(item, index) in SelectList" :key="index" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="" prop="Zip">
                            <el-input v-model="form.Zip" placeholder="Postal / Zip Code"></el-input>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="7. List account number(s) here (optional)">
                            <el-input v-model="form.optional" style="width: 96%;"></el-input>
                        </el-form-item>
                        <el-form-item label="8. Email" prop="Email">
                            <el-input v-model="form.Email" style="width: 96%;"></el-input>
                        </el-form-item>
                    </div>

                    <h3>Part I : Taxpayer Identification Number (TIN)</h3>
                    <div class="text">Enter your TIN in the appropriate field below. The TIN provided must match the name
                        given on line 1 to avoid backup withholding. For individuals, this is generally your social security
                        number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, see the Part I
                        instructions on page 3 on the original Form W-9.
                        For other entities, it is your employer identification number (EIN). If you do not have a number,
                        see How to get a TIN on page 3. Note. If the account is in more than one name, see the instructions
                        for line 1 and the chart on page 4 for guidelines on whose number to enter.</div>
                    <div class="width10"></div>
                    <!-- <el-form label-width="80px" :rules="rules"> -->
                    <el-form-item label="Selection of appropriate Tax Identification Number (TIN)" prop="Tax">
                        <el-radio-group v-model="form.Tax" @input="HandleInput">
                            <el-radio label="1">Individual</el-radio>
                            <el-radio label="2">Other Entities (i.e. Company)</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="Social Security Number (9 Digits)" v-if="Tax == 1" prop="Digits">
                        <el-input v-model="form.Digits" style="width: 40%;"></el-input>
                        <div class="text">Type in without hyphen (-)</div>
                    </el-form-item>

                    <el-form-item label="Employer Identification Number (9 Digits)" v-if="Tax == 2" prop="Employer">
                        <el-input v-model="form.Employer" style="width: 40%;"></el-input>
                        <div class="text">Type in without hyphen (-)</div>
                    </el-form-item>
                    <!-- </el-form> -->
                    <div class="width30"></div>
                    <h3>Part II : Certification</h3>
                    <div class="text">
                        Under penalties of perjury, I certify that:
                        1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a
                        number to be issued to me); and
                        2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I
                        have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding
                        as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I
                        am no longer subject to backup withholding; and
                        3. I am a U.S. citizen or other U.S. person (defined below); and
                        4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting
                        is correct.
                        Certification instructions. You must cross out item 2 above if you have been notified by the IRS
                        that you are currently subject to backup withholding because you have failed to report all interest
                        and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage
                        interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions
                        to an individual retirement arrangement (IRA), and generally, payments other than interest and
                        dividends, you are not required to sign the certification, but you must provide your correct TIN.
                        See the instructions on page 3.
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="Signature of U.S. person">
                            <vue-esign ref="esign" class="mySign" :isCrop="isCrop" :lineColor="lineColor"
                                :bgColor.sync="bgColor" />
                            <el-button @click="handleReset" class="handleReset">reset</el-button>
                        </el-form-item>
                        <el-form-item label="Date" prop="Date">
                            <el-date-picker v-model="form.Date" type="date" placeholder="(mm/dd/yyyy)"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </div>

                    <el-button type="success" @click="submitForm(rules)">Submit</el-button>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { District, Taxsb } from '@/api/home';
export default {
    data() {
        return {
            rules: {
                First: [
                    { required: true, message: 'First and Last are required.', trigger: 'blur' },
                ],
                radio: [
                    { required: true, message: 'Gender is required.', trigger: 'change' }
                ],
                rl: [
                    { required: true, message: 'Date of Birth is required.', trigger: 'blur' },
                ],
                boxes: [
                    { required: true, message: '3. Check appropriate box for federal tax classification; check only one of the following seven boxes: is required.', trigger: 'blur' },
                ],
                Email: [
                    { required: true, message: 'Email is required.', trigger: 'blur' },
                ],
                Tax: [
                    { required: true, message: 'Selection of appropriate Tax Identification Number (TIN) is required.', trigger: 'change' },
                ],
                Digits: [
                    { required: true, message: 'Social Security Number (9 Digits) is required.', trigger: 'blur' },
                ],
                Employer: [
                    { required: true, message: 'Employer Identification Number (9 Digits) is required.', trigger: 'blur' },
                ],
                Address1: [{
                    required: true, message: 'Address Line 1, City, State and Zip Code are required.', trigger: 'blur'
                },],
                city: [{
                    required: true, message: 'City is required.', trigger: 'blur'
                },],
                State: [{
                    required: true, message: 'State is required.', trigger: 'change'
                },],
                Zip: [{
                    required: true, message: 'Zip Code is required.', trigger: 'blur'
                }],
                Date: [{
                    required: true, message: 'Date is required.', trigger: 'blur'
                }],
            },
            SelectList: [],
            lineWidth: 2, // 画笔的线条粗细
            lineColor: "#000000", // 画笔的颜色
            bgColor: "", // 画布的背景颜色
            resultImg: "", // 最终画布生成的base64图片
            isCrop: false, // 是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
            Sign: {},//签名数据
            Tax: 999,
            Enter: 999,
            form: {
                fatca: "",
                payee: "",
                First: "",  // 姓
                Last: "",   // 名
                radio: "",  // Gender
                rl: "",     // 日历
                above: "",
                boxes: "",
                Tax: "",
                Digits: "",
                Employer: "",
                optional: "",
                Email: "",
                Address1: "",
                Address2: "",
                city: "",
                Zip: "",
                State: "",
                rl: "",
                Date: "",
                corporation: "",
                chosen: ""
            },
            Height: "",
        }
    },
    mounted() {
        this.HandleList();
        this.Height = document.documentElement.clientHeight;
    },
    methods: {
        // 获取美国城市列表
        HandleList() {
            District({ type: "us" }).then(res => {
                if (res.code == 200) {
                    this.SelectList = res.data
                }
            });
        },


        //清空
        handleReset() {
            this.$refs.esign.reset();
        },
        HandleInput(e) {
            this.Tax = e;
        },

        BoxChange(e) {
            this.Enter = e;
        },
        handleGenerate() {

        },
        submitForm(formName) {
            // this.handleGenerate();
            let { fatca,
                payee,
                First,
                Last,
                radio,
                above,
                boxes,
                Tax,
                Digits,
                Employer,
                optional,
                Email,
                Address1,
                Address2,
                city,
                Zip,
                State,
                rl,
                Date,
                chosen,
                corporation } = this.form;

            // 先進行一邊正則處理
            this.$refs.form.validate((valid) => {
                // 正則通過以後 把簽名進行生產，通過以後進行提交操作
                this.$refs.esign.generate().then(res => {
                    this.resultImg = res;
                    if (valid) {
                        Taxsb({
                            first_name: First, last_name: Last, gender: radio, birth: rl,
                            business_name: above, tax_class: boxes, address: Address1,
                            address_two: Address2, city: city, state: State, zip_code: Zip,
                            email: Email, date: Date, tax_identification_number_type: Tax, exempt_payee_code: payee,
                            fatca_code: fatca, account_number: optional, signature: this.resultImg, csp: corporation,
                            other_instructions: chosen, tax_identification_number: Digits, tax_identification_number_other: Employer
                        }).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: 'Information submitted successfully',
                                    type: 'success'
                                });
                                this.form = {};
                                this.handleReset()
                            }
                        });
                    }
                }).catch(err => {
                    this.$message.error(err) // 画布没有签字时会执行这里 'Not Signned'
                })

            });
        },
    },
}
</script>
<style lang="scss" scoped>
.el-radio {
    margin-top: 10px;
}

.mySign {
    width: 450px !important;
    border: 1px solid #DCDFE6;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
}

::v-deep .el-form-item__content {
    margin-left: 0px !important;
}

::v-deep .el-form-item__label {
    float: none;
    font-weight: bold;
}

::v-deep .el-input {
    margin-right: 20px;
}

::v-deep .el-form-item {
    width: 100%;
}

.width30 {
    width: 100%;
    height: 30px;
}

.width10 {
    width: 100%;
    height: 10px;
}

.home {
    width: 100%;
    min-width: 100%;
    min-height: 930px;
    background: rgba(204, 204, 204, 1);
    background-attachment: fixed;
    padding-bottom: 30px;

    .maxwidth {
        width: 1000px;
        background: #fff;
        margin: 0px auto;
        box-shadow: 0 0 10px hsla(0, 0%, 0%, .1), 1px 1px 2px hsla(0, 0%, 0%, .1);
        padding-bottom: 30px;

        .minwidth {
            width: 94%;
            margin: 0 auto;
            text-align: left;

            .text {
                font-size: 14px;
                line-height: 20px;
                color: #000000;
            }

            h1 {
                padding: 30px 0 0 0;
                margin: 0px 0 20px 0;

            }

            h3 {
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }
    }
}
</style>